<template>
  <div class="page-wrapper">
    <div class="panel" v-for="item in data" :key="item.id">
      <div class="question" @click="item.show = !item.show" :class="item.show && 'active'">
        {{ item.question }} <span class="arrow"></span>
      </div>
      <transition
        name="accordion-item"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition"
      >
        <div v-if="item.show">
          <div class="answer">
            {{ item.answer }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sss',
  data() {
    return {
      data: [
        {
          id: 1,
          question: 'Marlboro Roll’de nasıl bir harman kullanılıyor?',
          answer: `Marlboro Roll’de 5 farklı kıtadan toplanan ve üstün kaliteli tütünlerden oluşturulmuş özel bir Marlboro harmanı kullanılıyor.`,
          show: false,
        },
        {
          id: 2,
          question: 'Marlboro Roll’de bulunun kilitli paket özelliği ne işe yarıyor?',
          answer: `Marlboro Roll’de bulunan kilitli paket özelliği sayesinde, tütünün hava ile teması kesilerek nem oranı korunur. Bu sayede baştan sona aynı benzersiz içim deneyimi sunulur. Ayrıca kilitli paket, çantaya ve cebe tütün dökülmesini engeller.
            `,
          show: false,
        },
        {
          id: 3,
          question: 'Marlboro Roll’ün hem sarmaya hem de doldurmaya uygun olmasının sebebi nedir?',
          answer: `Uygun nem oranı ve kesim tekniği sayesinde, Marlboro Roll, hem sarmaya hem de doldurmaya uygundur. Bu şekilde mevcut kategoride yer alan yetişkin tüketicilerin farklı kullanım ritüellerine cevap verebilecek bir üründür.
            `,
          show: false,
        },
        {
          id: 4,
          question:
            "Sarmalık kıyılmış tütün ürünleri (gramajına göre) kaç adet paket/stick'e karşılık gelmektedir?",
          answer: `Hazırlanış şekli, boyutu, tütün tipi ve kesim kalınlığı gibi değişkenler yüksek etkiye sahip olsa da, 50g’lık bir Marlboro Roll paketi ile yaklaşık 3.5 paket sigaraya eşdeğer tütün ürünü hazırlanabilir.
            `,
          show: false,
        },
        {
          id: 5,
          question: "‘Sarma' ve 'doldurma' tütün ürünlerinin farkı nedir?",
          answer: `Sarma ve doldurma için ayrışan en temel özellikler nem oranı ve kesim kalınlığıdır. Sarmalık tütünler yüksek nem oranına sahip ve ince uzun saçaklı bir kesime sahipken, doldurma için daha uygun tütün ürünlerinde nem oranı oldukça düşüktür. Marlboro Roll hem sarmaya hem de doldurmaya uygundur.`,
          show: false,
        },
        {
          id: 6,
          question: 'Sigara ile içim deneyimi farklı mıdır?',
          answer: `Sigaraların kağıt ve filtre değişkenliği bulunmadığından, içim deneyimi istikrarlı ve dengelidir. Sarmalık kıyılmış tütün ürünleri bu değişkenlere bağlı olarak her kullanıcıda farklılaşabilir. Hem sarma hem de makarona uygun tütün ürünleri kesim ve nem oranı açısından sigaralara benzerlik gösteriyor olsa da, hiç bir sarmalık kıyılmış tütün ürününün, sigaralar ile aynı içim deneyimine sahip olduğunu söyleyemeyiz.`,
          show: false,
        },
        {
          id: 7,
          question: 'Sarmalık kıyılmış tütün kategorisindeki aksesuar çeşitleri nelerdir?',
          answer: `Elde sararak hazırlanan ürünler için sarma kağıdı ve isteğe bağlı olarak filtre gerekli aksesuarlar iken, doldurarak hazırlamak için boş makaron ve tüpleme cihazı gerekmektedir.`,
          show: false,
        },
        {
          id: 8,
          question: 'Piyasadaki açık tütün ürünlerinin yasal olup olmadığını nasıl anlayabiliriz?',
          answer: `Kategori fark etmeden piyasadaki tüm tütün ürünleri (Sigara, Sarmalık kıyılmış tütün, Sigarillo vs.) aynı kurallar doğrultusunda satılmaktadır. Türkiye'de tüm tütün mamülleri bandrollü olmak zorundadır. Ayrıca, Tek Tip Paket uygulaması, sarmalık kıyılmış tütün için de geçerlidir. Bu kategorinin de bütünlüğü bozulmamış paketler ile satılması gerekmektedir. Bu unsurlar, bir ürünün yasal olup olmadığı ile ilgili başlıca göstergelerdir.`,
          show: false,
        },
        {
          id: 9,
          question:
            'Mevcut TAPDK satış ruhsatları ile Sarmalık kıyılmış tütün ürünü satılabilir mi?',
          answer: `Evet. Bu kategori için de, sigara satmak için gerekli olan perakende tütün ruhsatına (PT) sahip olma zorunluluğu vardır. Bu kategori özelinde farklılaşan bir ruhsat bulunmadığından, mevcutta PT lisansına sahip satış noktaları, sarmalık tütün satışı da gerçekleştirebilir.`,
          show: false,
        },
        {
          id: 10,
          question: 'Fiyat etiketi uygulaması nasıl olacak?',
          answer: `Sigara kategorisinde olduğu gibi bulunduğu rafın altına, beyaz zemin üzerine en fazla 20 punto ile siyah renkte uygulanmalıdır.`,
          show: false,
        },
      ],
      show: false,
    };
  },
  mounted() {
    document.querySelector('#mainAppEllement').scrollTop = 0;
    document.querySelector('.main-inner-tab-bar').scrollTop = 0;
  },
  methods: {
    startTransition(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    endTransition(el) {
      el.style.height = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  padding: 20px;
}
.panel {
  overflow: hidden;
  .question {
    font-size: 18px;
    background-color: #f8f9f9;
    margin-bottom: 10px;
    min-height: 70px;
    padding: 10px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:after {
      content: url('~@/assets/icons/arrow.png');
      position: absolute;
      right: 20px;
      transition: transform 0.2s ease;
    }
    &.active {
      &:after {
        transform: rotate(-90deg);
      }
    }
  }
  .answer {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    padding-bottom: 20px;
  }
}
.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
</style>
